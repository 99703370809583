<template lang="pug">
.home
	h1 Welcome
	DefaultButton(@clicked="logout" text="login")
</template>

<script>
import DefaultButton from "../components/layout/DefaultButton";

export default {
	name: 'Welcome',
	components: {
		DefaultButton
	},
	methods: {
		logout() {
			this.$router.push({name: 'Login'})
		}
	}
}
</script>

<style lang="scss" scoped>
.home {
	min-height: calc(100vh - 80px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	h1 {
		margin-bottom: 20px;
	}
}
</style>
